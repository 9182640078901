import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import { fetchComponents } from "./action";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GenericError from "../GenericErrorPage";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";
import LayoutFormSupporto from "../../components/UI/formSupporto/LayoutFormSupporto";

export default function SupportForm({ path }) {
  const dispatch = useDispatch();
  const components = useSelector((state) => state.FormSupporto.components);

  useMemo(() => {
    dispatch(fetchComponents({ path }));
  }, [dispatch, path]);

  const formComponent = components?.components?.find(
    (v) => v.resourceType === "fi-form-login-support"
  );

  useEffect(() => {
    dispatch(coreComponentsActions.setHideNavAndFooter(true));
  }, [dispatch]);

  const { status, errorData } = GetStatusAndErrorData("FormSupporto");

  return (
    <>
      {!status && <PageLoader />}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        formComponent?.data && <LayoutFormSupporto data={formComponent?.data} />
      )}
    </>
  );
}
