import { DetailContainer } from "./style";
import PropTypes from "prop-types";

//components
import DateCategoryInfo from "./dateCategoryInfo/DateCategoryInfo";
import NewsDetailTitle from "./newsDetailTitle/NewsDetailTitle";
import NewsText from "./newsText/NewsText";
import NewsImage from "./newsImage/NewsImage";
import NewsDocContainer from "./newsDocContainer/NewsDocContainer";
import NewsTagContainer from "./newsTagContainer/NewsTagContainer";
import NewsVideo from "./newsVideo/NewsVideo";
import MainCarousel from "../../home/mainCarousel/MainCarousel";
import ThronPlayerWrapper from "../../thronPlayer/ThronPlayerWrapper";
import FiButton from "../../twoThirdTemplate/leftColumnContainer/fi-button/FiButton";
import EventDivider from "../../event/eventDetails/twoColumnContainer/leftColumnContainer/eventDivider/EventDivider";
import FaqAccordionList from "../../genericTemplate/faqAccordionList/faqAccordionList";
import GenericTitle from "../../genericTemplate/title/GenericTitle";
import HtmlBox from "../../htmlBox/htmlBox";

function NewsDetailContainer({ data }) {
  const detailContainerMapping = {
    "fi-title": GenericTitle,
    "fi-text": NewsText,
    "fi-image": NewsImage,
    "fi-button": FiButton,
    "fi-video": NewsVideo,
    "fi-carousel": MainCarousel,
    "fi-divider": EventDivider,
    "fi-faq-accordion-list": FaqAccordionList,
    "news-detail/fi-news-detail-documents": NewsDocContainer,
    "fi-html-box": HtmlBox,
    "thronconnector/components/thronmediaplayer": ThronPlayerWrapper,
  };

  return (
    <DetailContainer>
      <DateCategoryInfo />
      {data.map((current, idx) => {
        const ReactGenericComponent =
          detailContainerMapping[current.resourceType];
        return (
          ReactGenericComponent && (
            <ReactGenericComponent
              key={`${current.resourceType}-${idx}`}
              data={current.data}
            ></ReactGenericComponent>
          )
        );
      })}
      <NewsTagContainer />
    </DetailContainer>
  );
}

NewsDetailContainer.propTypes = {
  data: PropTypes.array,
};

export default NewsDetailContainer;
