import { FETCH_COMPONENTS, USER_SUPPORT_REQUEST } from "./constants";

export const fetchComponents = (payload) => ({
  type: FETCH_COMPONENTS,
  payload,
});

export const SendSupportRequest = (payload) => ({
  type: USER_SUPPORT_REQUEST,
  payload,
});
