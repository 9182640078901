import { FETCH_COMPONENTS, FETCH_YEAR_DOC } from "./constants";

export const fetchComponents = (payload) => ({
  type: FETCH_COMPONENTS,
  payload,
});
export const fetchYearDocuments = (payload) => ({
  type: FETCH_YEAR_DOC,
  payload,
});
