import { Carousel } from "./style";
import MyDropzone from "../../dropzone/Dropzone";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { SortableItem } from "./SortableItem";
export default function DroppableImages({
  otherImages,
  onOtherImagesChange,
  oiClassName,
  otherImagesLabel,
  setOtherImages,
  setCover,
  setDisplayCover,
  setOiClassName,
  onCoverChange,
  isFormAble,
}) {
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
  );
  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setOtherImages((items) => {
        const oldIndex = items.map((item) => item.id).indexOf(active.id);
        const newIndex = items.map((item) => item.id).indexOf(over.id);
        const newArray = arrayMove(items, oldIndex, newIndex);
        return newArray;
      });
    }
  }
  return (
    <Carousel>
      {otherImages.length > 0 && (
        <div style={{ display: "flex", gap: "32px", alignItems: "flex-start" }}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToParentElement]}
          >
            <SortableContext
              items={otherImages.map((item) => item.id)}
              strategy={rectSortingStrategy}
            >
              {otherImages.map((item) => (
                <SortableItem
                  key={item.id}
                  id={item.id}
                  base64URL={item.base64data}
                  setCover={setCover}
                  setDisplayCover={setDisplayCover}
                  file={item.file}
                  otherImages={otherImages}
                  setOtherImages={setOtherImages}
                  setOiClassName={setOiClassName}
                  onCoverChange={onCoverChange}
                  isFormAble={isFormAble}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
      )}

      {otherImages.length < 6 && (
        <MyDropzone
          onChange={onOtherImagesChange}
          className={oiClassName}
          placeholder={otherImagesLabel}
          uploadText={oiClassName === "dropzone-large"}
        ></MyDropzone>
      )}
    </Carousel>
  );
}
