import PropTypes from "prop-types";
// style
import { Container } from "./style";

// components
import EventDivider from "../../event/eventDetails/twoColumnContainer/leftColumnContainer/eventDivider/EventDivider";
import FiText from "./fi-text/FiText";
import FiButton from "./fi-button/FiButton";
import FiTitle from "./fi-title/FiTitle";
import FiImage from "./fi-Image/FiImage";
import NewsDocContainer from "./newsDocContainer/NewsDocContainer";
import Wrapper from "./divider/Divider";
import FaqAccordionList from "./faqAccordionList/faqAccordionList";
import ThronPlayerWrapper from "../../thronPlayer/ThronPlayerWrapper";
import StrumentiTitle from "../../strumenti/strumentiTitle/StrumentiTitle";
import MainCarousel from "../../home/mainCarousel/MainCarousel";
import FormazioneManagerialeBoxes from "./formazioneManagerialeBoxes/formazioneManagerialeBoxes";
import Tags from "./fi-welfare-tag/Tags";
import HtmlBox from "../../htmlBox/htmlBox";

function LeftColumnContainer({ leftColumnComponents }) {
  //  •	Text
  // •	Title
  // •	Title Description
  // •	Button
  // •	Carousel
  // •	Divider
  // •	Divider Gray
  // •	Documents
  // •	Image
  // •	Thron Universal Player
  // •	Formazione Manageriale
  // •	FAQ Accordion List

  const componentsMapping = {
    "fi-text": FiText,
    "fi-title": FiTitle,
    "fi-tools-title-description": StrumentiTitle,
    "fi-button": FiButton,
    "fi-carousel": MainCarousel,
    "fi-divider": EventDivider,
    "fi-welfare/fi-welfare-divider": Wrapper,
    "fi-contratto-documents": NewsDocContainer,
    "fi-image": FiImage,
    "thronconnector/components/thronmediaplayer": ThronPlayerWrapper,
    "fi-formazione-manageriale": FormazioneManagerialeBoxes,
    "fi-welfare/fi-welfare-tags": Tags,
    "fi-faq-accordion-list": FaqAccordionList,
    "fi-html-box": HtmlBox,
  };

  return (
    <Container>
      {leftColumnComponents?.map((element, idx) => {
        const ReactGenericComponent = componentsMapping[element.resourceType];
        return (
          ReactGenericComponent && (
            <ReactGenericComponent
              key={`${element.resourceType}-${idx}`}
              data={element.data}
            />
          )
        );
      })}
    </Container>
  );
}

LeftColumnContainer.propTypes = {
  leftColumnComponents: PropTypes.arrayOf(PropTypes.object),
};

export default LeftColumnContainer;
