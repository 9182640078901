import { createSlice } from "@reduxjs/toolkit";

export const FORM_SUPPORTO_SCOPE = "FormSupporto";

export const initialState = {
  components: {},
  status: null,
  errorMessage: "",
  bodyPayload: {
    firstname: "",
    lastname: "",
    email: "",
    subject: "",
    subjectCode: "",
    message: "",
    captchaValue: "",
  },
};

const FormSupportoSlice = createSlice({
  name: FORM_SUPPORTO_SCOPE,
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setBodyPayload(state, action) {
      state.bodyPayload = action.payload;
    },
    setStatusNull(state) {
      state.status = null;
    },
    resetFormSupporto: () => initialState,
  },
});

export const {
  setComponents,
  setError,
  setStatusNull,
  setBodyPayload,
  resetFormContraffazioni,
} = FormSupportoSlice.actions;
export default FormSupportoSlice;
