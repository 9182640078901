import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  components: {},
  status: null,
  openContactModal: false,
  errorMessage: "",
};

const offerDetailSlice = createSlice({
  name: "offerDetail",
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setContactModal(state, action) {
      state.openContactModal = action.payload;
    },
    setStatusNull(state) {
      state.status = null;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    resetOfferDetail: () => initialState,
  },
});

export const offerDetailActions = offerDetailSlice.actions;
export default {
  name: offerDetailSlice.name,
  reducer: offerDetailSlice.reducer,
};
