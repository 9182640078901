import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  components: [],
  settings: {},
  welfareCardList: [],
  welfareCardListTarget: [],
  welfareCardListAll: [],
  status: null,
  errorMessage: "",
  tabsMap: [],
  tabCategory: "target",
  tags: null,
  cardsList: null,
  inputText: "",
};

const welfareSlice = createSlice({
  name: "welfare",
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload.components;
      state.settings = action.payload.settings;
    },
    setWelfareCardList(state, action) {
      state.welfareCardList = action.payload;
    },
    setWelfareCardListTarget(state, action) {
      state.welfareCardListTarget = action.payload;
    },
    setWelfareCardListAll(state, action) {
      state.welfareCardListAll = action.payload;
    },
    setTabsMap(state, action) {
      state.tabsMap = action.payload;
    },
    setTabCategory(state, action) {
      state.tabCategory = action.payload;
      action.payload === "target"
        ? (state.welfareCardList = state.welfareCardListTarget)
        : (state.welfareCardList = state.welfareCardListAll);
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

export const welfareActions = welfareSlice.actions;
export default {
  name: welfareSlice.name,
  reducer: welfareSlice.reducer,
};
