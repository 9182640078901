import styled from "styled-components";

export const Wrapper = styled.div`
  clear: both;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 1280px) {
    margin-bottom: 2em;
  }
  @media screen and (max-width: 1279px) {
  }
`;

export const SelectMobileWrapper = styled.div`
  width: 100%;
  flex-direction: column;
  gap: 32px;
  display: flex;
  select {
    width: 100%;
  }
`;

export const Title = styled.h2`
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
`;

export const SelectContainer = styled.div`
  gap: 32px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const SelectItem = styled.div`
  position: relative;
  display: block;
  width: 298px;
  @media screen and (max-width: 1279px) {
    width: 100%;
  }
`;

export const ContainerTabs = styled.div`
  display: flex;
  place-content: center;
  margin: 46px 0px 66px;
  padding: 0px;
`;
export const ButtonTab = styled.button`
  display: flex;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 1px;
  padding: 12px 32px;
  border: 2px solid transparent;
  cursor: pointer;

  &:hover {
    color: rgb(218, 41, 28);
    border-bottom: 2px solid rgb(218, 41, 28);
  }
  ${(props) =>
    props.disabled &&
    "&:hover {color: rgb(112, 112, 112);border-bottom: 0px;cursor: unset;}"}
  ${(props) =>
    props.active
      ? "color: rgb(218, 41, 28);border-bottom: 2px solid rgb(218, 41, 28);"
      : "color: rgb(112, 112, 112);"};

  @media screen and (max-width: 1279px) {
    padding: 12px 24px;
  }
`;

export const TitleTabsMobile = styled.p`
  margin: auto;
  text-transform: uppercase;
  font-size: 13px;
`;
