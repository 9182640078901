import React from "react";

import RecapCard from "./recapCard/RecapCard";
export function RecapCardsList({ subscriptions, data }) {
  return (
    <>
      {subscriptions?.map((subscription, i) => (
        <RecapCard
          key={`simpleEventCard-${i}`}
          subscription={subscription}
          data={data}
        />
      ))}
    </>
  );
}

export default React.memo(RecapCardsList);
