import { Container, SendButton, GenericTitle } from "./style";
import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBodyPayload } from "../../../pages/FormSupporto/FormSupportoSlice";
import { SendSupportRequest } from "../../../pages/FormSupporto/action";

import ComponentPersonalData from "./components/ComponentPersonalData";
import ComponentReason from "./components/ComponentReason";
import Captcha from "./components/Captcha";

function LayoutFormSupporto({ data }) {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  //selectors
  const bodyPayload = useSelector((state) => state.FormSupporto.bodyPayload);
  const language =  window.location.pathname.split('/form-login-support')?.[0];
  //useState
  const [allowed, setAllowed] = useState(null);
  const [buttonHasBeenClicked, setButtonHasBeenClicked] = useState(false);
  const isSubmitActive =
    (/^E_\d{11}$/.test(bodyPayload?.subjectCode) ||
      /^0\d{15}$/.test(bodyPayload?.subjectCode)) &&
    !Object.values(bodyPayload).includes("");

  const handleEventInfo = (e) => {
    const { name, value } = e.currentTarget;
    dispatch(
      setBodyPayload({
        ...bodyPayload,
        [name]: value,
      })
    );
  };

  async function submitForm(e) {
    e.preventDefault();
    const emailTo = data?.emailTo;
    setBodyPayload({
      ...bodyPayload,
      emailTo,
    });
    try {
      setButtonHasBeenClicked(true);
      if (isSubmitActive) {
        setAllowed(true);
        const updatedBodyPayload = {
          ...bodyPayload,
          emailTo: emailTo,
        };
        dispatch(
          SendSupportRequest({
            updatedBodyPayload,
            language
          })
        );
      } else {
        setAllowed(false);
        const element =
          document.getElementById("required")?.previousElementSibling;
        if (element)
          element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    } catch (e) {
      console.error(e);
      document.body.classList.remove("loading");
    }
  }

  useEffect(() => {
    const element = document.getElementById("required")?.previousElementSibling;
    if (buttonHasBeenClicked) {
      element &&
        !allowed &&
        element?.scrollIntoView({ behavior: "smooth", block: "center" });
      setButtonHasBeenClicked(false);
    }
  }, [allowed]);
  return (
    <Container>
      <GenericTitle>
        <img src="/img/logo-black.svg" className="logo" />
        <h2>{data?.title}</h2>
      </GenericTitle>
      <form
        onSubmit={(e) => {
          submitForm(e);
        }}
        ref={formRef}
      >
        <ComponentPersonalData
          data={data}
          bodyPayload={bodyPayload}
          allowed={allowed}
          handleEventInfo={handleEventInfo}
        />
        <ComponentReason
          data={data}
          bodyPayload={bodyPayload}
          allowed={allowed}
          handleEventInfo={handleEventInfo}
        />
        <Captcha data={data} bodyPayload={bodyPayload} allowed={allowed} />
        <SendButton
          className={
            isSubmitActive ? "submitButton activeSubmit" : "submitButton"
          }
        >
          {data?.buttonLbl}
        </SendButton>
      </form>
    </Container>
  );
}

export default LayoutFormSupporto;
