import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  components: {},
  status: null,
  errorMessage: "",
};

const newsDetailSlice = createSlice({
  name: "newsDetail",
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setStatusNull(state) {
      state.status = null;
    },
    resetNewsDetail: () => initialState,
  },
});

export const newsDetailActions = newsDetailSlice.actions;
export default {
  name: newsDetailSlice.name,
  reducer: newsDetailSlice.reducer,
};
