import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
// actions
import {
  fetchComponents,
  sendReadNotifications,
  sendReadAllNotifications,
} from "./actions";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
// selectors
import { selectNotificationsByFilterAndYear } from "./selectors";
// utils
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
// components
import GenericError from "../GenericErrorPage";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import LayoutContratto from "../../components/UI/contratto/LayoutContratto";
import NotificationTop from "../../components/UI/notifications/page/NotificationTop";
import NotificationCard from "../../components/UI/dialog/notifications/dialogContent/notificationCard/NotificationCard";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";

const COMPONENTS_MAPPING = {
  "fi-notifications-catalogue": NotificationTop,
};

function Notifications({ path }) {
  const dispatch = useDispatch();

  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const coreLang = useSelector((state) => state.coreComponents.language);
  const token = useSelector((state) => state.login.activeToken);
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const title = headerLinks?.find((v) => v.path === path)?.title;
  const { components, notificationsArray, filterType, year } = useSelector(
    (state) => state.notifications
  );
  const currentNotifications = useSelector(
    selectNotificationsByFilterAndYear(filterType, year)
  );
  const { status, errorData } = GetStatusAndErrorData("notifications");

  const markSelectedReadHandler = useCallback(() => {
    const currentDocumentKeys = currentNotifications.reduce((acc, el) => {
      if (!el.read) acc.push(el.idPath);
      return acc;
    }, []);

    dispatch(
      sendReadNotifications({ currentDocumentKeys, coreLang, token, path })
    );
  }, [dispatch, currentNotifications, coreLang, token, path]);

  const markAllReadHandler = useCallback(() => {
    dispatch(sendReadAllNotifications({ coreLang, token, path }));
  }, [dispatch, notificationsArray, coreLang, token, path]);

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(true));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
    dispatch(fetchComponents({ path, token }));
  }, [dispatch, path, token]);
  ChangeTitleDoc(subjectCode, aggregatedDirection, title, path);
  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        components?.components && (
          <LayoutContratto heroImg={components.settings.heroImage}>
            {components?.components?.map((current, idx) => {
              const ReactGenericComponent =
                COMPONENTS_MAPPING[current.resourceType];
              return (
                ReactGenericComponent && (
                  <ReactGenericComponent
                    key={`${current.resourceType}-${idx}`}
                    data={current.data}
                    markAllReadHandler={markAllReadHandler}
                    markSelectedReadHandler={markSelectedReadHandler}
                  ></ReactGenericComponent>
                )
              );
            })}
            {React.Children.toArray(
              currentNotifications.map((notification) => {
                return <NotificationCard data={notification} />;
              })
            )}
          </LayoutContratto>
        )
      )}
    </>
  );
}

export default Notifications;
