import { Wrapper, ContainerFullHeight } from "./style";
import { useEffect, useState } from "react";

function HtmlBox({ data }) {
  const [iframeHeight, setiframeHeight] = useState();

  function html() {
    return { __html: data.html };
  }

  function isFullHeight() {
    return data?.typeWeb === "fullHeight";
  }

  const iframe = document.querySelector("iframe");

  useEffect(() => {
    const fullHeight = data?.fullHeightTxt ? data?.fullHeightTxt + "px" : "";
    fullHeight && setiframeHeight(fullHeight);
  }, []);

  return (
    <>
      {isFullHeight() ? (
        <ContainerFullHeight
          iframeHeight={iframeHeight}
          dangerouslySetInnerHTML={html()}
        />
      ) : (
        <Wrapper dangerouslySetInnerHTML={html()} />
      )}
    </>
  );
}

export default HtmlBox;
