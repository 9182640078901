import styled from "styled-components";

export const SelectWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  label {
    font-size: 11px;
    color: var(--carousel-dot-color);
    margin: 0px;
    ${(props) => (props.hideLabel ? "opacity:0" : "opacity:1")};
  }
`;

export const Select = styled.select`
  cursor: pointer;
  width: 100%;
  appearance: none;
  position: relative;
  ${(props) => (props.noborder ? "border:none" : "")};
  &,
  input,
  option {
    font-family: ferrari-sans-regular;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding: 12px 16px 14px;
    color: var(--main-color);
    max-width: 100%;
    background: transparent;
  }
  optgroup {
    color: rgb(112, 112, 112) !important;
  }
  &.placeholder {
    color: rgb(112, 112, 112) !important;
  }
  select:not(:-internal-list-box) {
    overflow: visible !important;
  }
`;

export const WrapperImg = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;
  max-width: 100%;
`;
