import { FETCH_WELFARE_COMPONENTS } from "./constants";
import { welfareActions } from "./welfareSlice";
import { takeLatest, put, select } from "redux-saga/effects";
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";

function* fetchWelfareComponents({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    // const result = yield call(() => axios.get("/mocks/mock-welfare.json"));
    yield put(welfareActions.setComponents(result.data.data));
    const tabsObject = yield select(
      (state) =>
        state.welfare.components.find(
          (el) => el.resourceType === "fi-welfare/fi-welfare-catalogue"
        )?.data
    );

    const tabsMap = [
      {
        category: "target",
        title: tabsObject?.suggestedLabel,
        // disabled: !haveTargetCards,
      },
      {
        category: "noTarget",
        title: tabsObject?.allLabel,
        // disabled: false,
      },
    ];

    yield put(welfareActions.setTabsMap(tabsMap));
    yield put(
      welfareActions.setWelfareCardListTarget(tabsObject.forYouResults)
    );
    yield put(welfareActions.setWelfareCardListAll(tabsObject.forAllResults));
    yield put(welfareActions.setTabCategory("target"));
  } catch (e) {
    console.error(e);
    yield put(welfareActions.setError(e));
  }
}

export default function* newsSaga() {
  yield takeLatest(FETCH_WELFARE_COMPONENTS, fetchWelfareComponents);
}
