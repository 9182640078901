import styled from "styled-components";

export const Container = styled.section`
  width: 600px;
  margin: 0 auto 96px;
  padding: 75px 0;
  ${(props) =>
    props.center
      ? "display: flex; flex-direction:column; align-items: center"
      : "display: block"};

  & select {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #8f8f8f;
    padding-left: 0;
    font-size: 14px;
  }
  & input {
    letter-spacing: 0.5px;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 40px;
  }

  @media (max-width: 1279px) {
    max-width: 800px;
    width: 80%;
    margin: 0 auto 96px;
    h2 {
      margin: 40px 0 20px;
    }
  }
`;
export const GenericTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-contente: center;
  align-items: center;
  width: 100%;
  margin: 0px auto;
  padding: 0;
  h2 {
    margin: auto;
    font-size: 28px;
    font-family: ferrari-sans-medium, sans-serif;
    line-height: 34px;
    font-weight: 400;
  }
  img {
    margin-bottom: 15px;
    width: 26px;
    height: auto;
  }
  @media (max-width: 1279px) {
    width: auto;
  }
`;
export const SendButton = styled.button.attrs((props) => ({
  type: "submit",
}))`
  width: 100%;
  padding: 20px 0px;
  margin-top: 20px;
  cursor: pointer;
  border: 0px;
  text-transform: uppercase;
  font-size: 12px;
  background-blend-mode: overlay, overlay, normal;
  background: #d4d2d2;
  color: #181818;
  &.activeSubmit {
    color: rgb(255, 255, 255);
    background: radial-gradient(
        199.89% 50% at 50% 100%,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(
        349.37% 116.89% at 50% 100%,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      rgb(218, 41, 28);
  }

  @media (max-width: 1279px) {
    max-width: 100%;
  }
`;

export const ComponentContainer = styled.div`
  h3 {
    text-transform: uppercase;
    span {
      text-transform: lowercase;
      color: #8f8f8f;
      font-weight: 400;
      font-size: 11px;
      line-height: 20px;
      letter-spacing: 1px;
    }
  }

  textarea {
    width: 100%;
    height: 150px;
    font-size: 14px;
    font-family: ferrari-sans-regular;
    line-height: 20px;
    letter-spacing: 0, 5px;
    resize: none;
    border-color: #8f8f8f;
    background-color: transparent;
  }
  textarea::placeholder {
    color: #8f8f8f;
  }
`;

export const InputSection = styled.div`
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  margin: 10px 0;
  label {
    font-size: 12px;
    color: var(--carousel-dot-color);
    margin-bottom: 5px;
    ${(props) => (props.hideLabel ? "opacity:0" : "opacity:1")};
  }
  input {
    background: transparent;
    width: 100%;
    border-width: 0px 0px 1px;
    border-top-style: initial;
    border-top-color: initial;
    border-left-style: initial;
    border-left-color: initial;
    border-right-style: initial;
    border-right-color: initial;
    border-bottom-style: solid;
    border-bottom-color: rgb(143, 143, 143);
    font-size: 14px;
    padding: 7px 0px;
    padding: 10px 0px;
    color: #303030;
    :: -webkit-outer-spin-button,
    :: -webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    ::input[type="number"] {
        -moz-appearance: textfield;
      }
    }
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 0px;
    }
  }
  & input::placeholder {
    color: #707070;
  }
  textarea:not(:placeholder-shown) + label ,
  input:not(:placeholder-shown) + label {
    opacity: 1;
  }
  input.disabled {
    border-color: none;
    border-width: 0px;
  }
`;

export const ContainerInfo = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 7px;
`;
export const InfoIcon = styled.svg.attrs({
  width: "28",
  height: "28",
  viewBox: "0 0 26 26",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
})`
  pointer-events: fill;
`;
export const InfoCircle = styled.path.attrs({
  fillRule: "evenodd",
  d: "M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12.0031 22.8031C17.9678 22.8031 22.8031 17.9678 22.8031 12.0031C22.8031 6.03845 17.9678 1.20312 12.0031 1.20312C6.03845 1.20312 1.20312 6.03845 1.20312 12.0031C1.20312 17.9678 6.03845 22.8031 12.0031 22.8031Z",
})`
  fill: #d4d2d2;
  pointer-events: fill;
`;
export const InfoImg = styled.path.attrs({
  fillRule: "evenodd",
  d: "M12.7992 11.2016V16.8016H11.1992V12.8016C11.1992 11.9447 11.8728 11.2451 12.7194 11.2035L12.7992 11.2016ZM11.9992 8C12.441 8 12.7992 8.35817 12.7992 8.8C12.7992 9.24183 12.441 9.6 11.9992 9.6C11.5574 9.6 11.1992 9.24183 11.1992 8.8C11.1992 8.35817 11.5574 8 11.9992 8Z",
})`
  fill: var(--main-color);
  pointer-events: fill;
`;
