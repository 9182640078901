import { RadioButton } from "../../style";

export default function RadioButtonInput({
  data,
  handleEventInfo,
  showTextOnInput,
  bodyPayload,
}) {
  return data?.appointments?.map((appointment, i) => (
    <RadioButton key={appointment?.id}>
      <input
        type="radio"
        id={`appointment-${appointment?.label}`}
        name={appointment?.label}
        defaultValue={appointment?.id}
        checked={
          bodyPayload?.events?.find((sub) => sub?.eventCode === data?.eventCode)
            ?.appointmentId === appointment?.id
        }
        onChange={(e) => handleEventInfo(e, data?.eventCode, data?.consents)}
      />
      <label htmlFor={`appointment-${appointment?.label}`}>
        {showTextOnInput(data, appointment)}
      </label>
    </RadioButton>
  ));
}
