import { combineReducers } from "redux";

const context = require.context(
  "../",
  true,
  /(pages|utils)\/[\s\S]*?\/[a-zA-Z0-9]*Slice.js$/
);

const reducers = context.keys().map((filename) => context(filename).default);

const rootReducer = combineReducers({
  ...reducers.reduce((acc, el) => ({ ...acc, [el.name]: el.reducer }), {}),
});

export default rootReducer;
