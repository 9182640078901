import { takeLatest, put, call } from "redux-saga/effects";
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";
import { FETCH_COMPONENTS } from "./constants";

import { RecapAndDetailGiftHouse2024Actions } from "./RecapAndDetailGiftHouse2024Slice";
import axios from "axios";

function* fetchComponentsWorker({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    // const result = yield call(() =>
    //   axios.get("/mocks/mock-gifthouse-detail-2024.json")
    // );
    yield put(
      RecapAndDetailGiftHouse2024Actions.setComponents(result.data.data)
    );
  } catch (e) {
    console.error(e);
    yield put(RecapAndDetailGiftHouse2024Actions.setError(e));
  }
}

export default function* FormAnnuncioSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchComponentsWorker);
}
