import React from "react";
import {
  ButtonContainer,
  CtaButton,
  CtaContainerButton,
  CtaLink,
} from "./style";
import SetQueryStringPath from "../../../../../../utils/setQueryStringPath";
import { useDispatch, useSelector } from "react-redux";
import { myPageActions } from "../../../../../../pages/MyPage/myPageSlice";
import { Link } from "react-router-dom";
import { setShowRecap } from "./../../../../../../pages/SimpleEventsSubscribeForm/SimpleEventsSubscribeFormSlice";

export function ButtonsCta({ data }) {
  const isRecapPage = true;
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.coreComponents);

  const handleRedirect = () => {
    dispatch(myPageActions.setTabIndex(3));
    dispatch(setShowRecap(false));
  };

  const setUrl = (url) => {
    let result = url;
    if (url) {
      if (url.includes("news-and-events/events/")) {
        const fixed = "/event-details?path=";
        const splittedUrl = url.split(`${language}/`);
        result = fixed + `/${language}/` + splittedUrl[1];
      }
    }

    return result;
  };

  return (
    <ButtonContainer>
      {
        <Link
          onClick={() => dispatch(setShowRecap(false))}
          to={"/event-details?path=" + data?.leftBtn?.link}
        >
          <CtaButton
            color="var(--main-color)"
            bg="transparent"
            border="1px solid #d4d4d4"
          >
            {data?.leftBtn?.title}
          </CtaButton>
        </Link>
      }
      {/* {
        <CtaLink href={SetQueryStringPath(data?.leftBtn?.link)}>
          <CtaButton
            color="var(--main-color)"
            bg="transparent"
            border="1px solid #d4d4d4"
          >
            {data?.leftBtn?.title}
          </CtaButton>
        </CtaLink>
      } */}
      <CtaContainerButton>
        <Link
          style={{ pointerEvents: !data?.rightBtn?.link ? "none" : "pointer" }}
          onClick={() => isRecapPage && handleRedirect()}
          to={setUrl(data?.rightBtn?.link)}
        >
          <CtaButton>{data?.rightBtn?.title}</CtaButton>
        </Link>
      </CtaContainerButton>
    </ButtonContainer>
  );
}
