import { ComponentContainer, InputSection } from "../style";
import ErrorField from "./ErrorField/ErrorField";

export default function ComponentReason({
  data,
  bodyPayload,
  allowed,
  handleEventInfo,
}) {
  return (
    <>
      <ComponentContainer>
        <h3>{data?.requestTxt} </h3>
        <InputSection hideLabel={true}>
          <input
            autoComplete="nope"
            id="subject"
            name="subject"
            placeholder={data?.subjectLbl}
            defaultValue={bodyPayload?.subject}
            type="text"
            onChange={(e) => {
              handleEventInfo(e);
            }}
          />
          <label htmlFor="subject">{data?.subjectLbl}</label>
        </InputSection>
        {!bodyPayload?.subject && allowed === false && (
          <ErrorField text={data?.errorMessage} />
        )}
      </ComponentContainer>
      <ComponentContainer>
        <InputSection hideLabel={true}>
          <textarea
            autoComplete="nope"
            id="message"
            name="message"
            placeholder={data?.bodyLbl}
            rows="5"
            cols="33"
            onChange={(e) => handleEventInfo(e)}
          ></textarea>
          <label htmlFor="message">{data?.bodyLbl}</label>
        </InputSection>
        {!bodyPayload?.message && allowed === false && (
          <ErrorField text={data?.errorMessage} />
        )}
      </ComponentContainer>
    </>
  );
}
