import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { coreComponentsActions } from "./getCoreComponents/coreComponentsSlice";
import { useDispatch, useSelector } from "react-redux";
import { eventDetailsActions } from "../pages/EventDetails/eventDetailsSlice";
import { newsDetailActions } from "../pages/NewsDetail/newsDetailSlice";
import { container800Actions } from "../pages/Container800/containerSlice";
import { adsListActions, resetAdsList } from "../pages/AdsList/AdsListSlice";
import { offerDetailActions } from "../pages/OfferDetail/offerDetailSlice";
import { twoThirdTemplateActions } from "../pages/TwoThirdTemplate/twoThirdTemplateSlice";
import { RecapAndDetailMondialiActions } from "../pages/RecapAndDetailMondiali/RecapAndDetailMondialiSlice";
import { resetFormDataFamilyDay } from "../pages/FormIscrizioneFamilyDay/FormIscrizioneFamilyDaySlice";
import { resetFormDataMondiali } from "../pages/FormIscrizioneMondiali/FormIscrizioneMondialiSlice";
import { resetFormDataGiftHouse } from "../pages/FormIscrizioneGiftHouseLe/FormIscrizioneGiftHouseSlice";
import { resetFormDataSimpleEvents } from "../pages/SimpleEventsSubscribeForm/SimpleEventsSubscribeFormSlice";
import { resetFormDataRequestEcard } from "../pages/FormRichiestaEcard/FormRichiestaEcardSlice";
import { resetFormDataTestHmi } from "../pages/FormTestHmi/FormTestHmiSlice";
import { resetFormDataAnnuncio } from "../pages/FormAnnuncio/FormAnnuncioSlice";
import { RecapAndDetailGiftHouseLeActions } from "../pages/RecapAndDetailGiftHouseLe/RecapAndDetailGiftHouseLeSlice";
import { resetRecapAndDetailFamilyDay } from "../pages/RecapAndDetailFamilyDay/RecapAndDetailFamilyDaySlice";
import { resetFormContraffazioni } from "../pages/FormContraffazioni/FormContraffazioniSlice";
import { fullWidthActions } from "../pages/FullWidth/containerSlice";
import { resetFormDataGiftHouse2024 } from "../pages/FormIscrizioneGiftHouse2024/FormIscrizioneGiftHouse2024Slice";
import { RecapAndDetailGiftHouse2024Actions } from "../pages/RecapAndDetailGiftHouse2024/RecapAndDetailGiftHouse2024Slice";
import { companyComActions } from "../pages/CompanyCom/companyComSlice";
// this function take also some actions that need to be done on page load
// TODO change name function
export default function ScrollToTop() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { initialComponents } = useSelector((state) => state.coreComponents);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "";
    if (window.THRONPLAYER) {
      window.THRONPLAYER.forEach((v) => v.destroy());
      window.THRONPLAYER = [];
    }
    initialComponents && dispatch(coreComponentsActions.setInitialComponents());
    // here reset common templates
    dispatch(coreComponentsActions.setHideNavAndFooter(false));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
    dispatch(coreComponentsActions.setIsHorizontalFooter(false));
    dispatch(companyComActions.resetCompanyCom());
    //here reset forms data
    dispatch(resetFormDataFamilyDay());
    dispatch(resetFormDataMondiali());
    dispatch(resetFormDataGiftHouse());
    dispatch(resetFormDataGiftHouse2024());
    dispatch(resetFormDataSimpleEvents());
    dispatch(resetFormDataRequestEcard());
    dispatch(resetFormContraffazioni());
    dispatch(resetFormDataTestHmi());
    dispatch(resetFormDataAnnuncio());
    dispatch(resetRecapAndDetailFamilyDay());
    dispatch(resetAdsList());
    dispatch(newsDetailActions.resetNewsDetail());
    dispatch(container800Actions.resetContainer800());
    dispatch(RecapAndDetailMondialiActions.resetRecapAndDetailMondiali());
    dispatch(eventDetailsActions.resetEventDetail());
    dispatch(twoThirdTemplateActions.resetTwoThirdTemplate());
    dispatch(fullWidthActions.resetFullWidth());
    dispatch(offerDetailActions.resetOfferDetail());
    dispatch(RecapAndDetailGiftHouseLeActions.resetRecapAndDetailGiftHouse());
    dispatch(
      RecapAndDetailGiftHouse2024Actions.resetRecapAndDetailGiftHouse2024()
    );
  }, [pathname, dispatch, initialComponents]);

  return null;
}
