import React from "react";
import { useSelector } from "react-redux";
import { LayoutSimpleEventsRecap } from "./components/recapPage/LayoutSimpleEventsRecap";
import { selectHeroImg } from "../../../pages/SimpleEventsSubscribeForm/selectors";
import Header from "./components/Hero/Hero";
import LayoutSubscriptionForm from "./components/subscriptionForm/LayoutSubscriptionForm";

function LayoutSimpleEventsSubscribeForm({ data }) {
  const lang = useSelector((state) => state.coreComponents.language);
  const heroData = useSelector(selectHeroImg());
  const showRecap = useSelector(
    (state) => state.SimpleEventsSubscribeForm.showRecap
  );

  const getAppointmentHours = (startDate, endDate, lang) => {
    let result = new Date(startDate)?.toLocaleString(lang, {
      hour: "2-digit",
      minute: "2-digit",
    });
    result = `${result} - ${new Date(endDate)?.toLocaleString(lang, {
      hour: "numeric",
      minute: "2-digit",
    })}`;
    return result.charAt(0).toUpperCase() + result.slice(1);
  };
  const showTextOnInput = (event, appointment) =>
    event?.pickSlotTitle === "false"
      ? getAppointmentHours(
          appointment?.appointmentStartDate,
          appointment?.appointmentEndDate,
          lang
        )
      : appointment?.title;

  return (
    <>
      {heroData?.fileReference && <Header data={heroData} />}
      {!showRecap ? (
        <LayoutSubscriptionForm data={data} showTextOnInput={showTextOnInput} />
      ) : (
        <LayoutSimpleEventsRecap events={data?.eventItems?.items} />
      )}
    </>
  );
}

export default LayoutSimpleEventsSubscribeForm;
