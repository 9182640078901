import PropTypes from "prop-types";
import { DocContainer, DocContainerTitle, DocumentsWrapper } from "./style";

import NewsDoc from "./newsDoc/NewsDoc";

function NewsDocContainer({ elementData }) {
  const NEWS_DOC_KEY = "NEWS-DOCUMENT-";
  return (
    <DocumentsWrapper>
      <DocContainer className="doc-container">
        <DocContainerTitle className="doc-container__title">
          {elementData.title}
        </DocContainerTitle>
        {elementData.assets.map((currentDoc, idx) => (
          <NewsDoc key={`${NEWS_DOC_KEY}-${idx}`} data={currentDoc} />
        ))}
      </DocContainer>
    </DocumentsWrapper>
  );
}

NewsDocContainer.propTypes = {
  data: PropTypes.exact({
    title: PropTypes.string,
    assets: PropTypes.array,
    position: PropTypes.string,
  }),
};

export default NewsDocContainer;
