import React from "react";
import {
  DetailContainerData,
  DetailDataLabel,
  DetailState,
  DetailWrapper,
} from "./style";
import { useSelector } from "react-redux";

export function RecapCard({ subscription, data }) {
  const lang = useSelector((state) => state.coreComponents.language);
  const responsePost = useSelector(
    (state) => state.SimpleEventsSubscribeForm.resultPost
  );

  const successResponses = responsePost?.filter(
    (el) => el.status === "fulfilled"
  );

  let isFulfilled = false;
  if (
    successResponses.find(
      (singleResponseEl) =>
        subscription.id === singleResponseEl?.value?.appointmentId
    )
  ) {
    isFulfilled = true;
  }

  const getAppointmentDateExtended = (startDate, endDate, lang) => {
    let result = new Date(startDate)?.toLocaleString(lang, {
      weekday: "long",
      day: "2-digit",
      month: "long",
      hour: "2-digit",
      minute: "2-digit",
    });
    result = `${result} - ${new Date(endDate)?.toLocaleString(lang, {
      hour: "numeric",
      minute: "2-digit",
    })}`;
    return result.charAt(0).toUpperCase() + result.slice(1);
  };
  const showTextOnInputRecap = (event, appointment) =>
    event?.pickSlotTitle === "false"
      ? getAppointmentDateExtended(
          appointment?.appointmentStartDate,
          appointment?.appointmentEndDate,
          lang
        )
      : appointment?.title;

  return (
    <>
      <DetailWrapper>
        <DetailContainerData>
          <DetailDataLabel>{data?.eventLabel}</DetailDataLabel>
          <p>{subscription?.eventTitle}</p>
        </DetailContainerData>
        <DetailContainerData>
          <DetailDataLabel>{data?.stateLabel}</DetailDataLabel>
          <DetailState success={isFulfilled}>
            {isFulfilled ? data?.successTxt : data?.failedTxt}
          </DetailState>
        </DetailContainerData>
        {isFulfilled && (
          <>
            <DetailContainerData>
              <DetailDataLabel>
                {data?.pickSlotTitle === "true"
                  ? data?.slotTitleLabel
                  : data?.slotLabel}
              </DetailDataLabel>
              <p>{showTextOnInputRecap(data, subscription)}</p>
            </DetailContainerData>
          </>
        )}
      </DetailWrapper>
    </>
  );
}

export default React.memo(RecapCard);
