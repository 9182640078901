import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 384px;
  @media (min-width: 769px) {
    width: 304px;
  }
  @media (min-width: 1280px) {
    width: 384px;
  }
  @media (max-width: 1279px) {
    height: auto;
  }
  opacity: 0;
  transition: opacity 1s;
  background: var(--alternative-bg-color);
  a {
    width: 90%;
    margin-left: 32px;
    @media (max-width: 1279px) {
      margin-left: 24px;
    }
    display: flex;
    align-items: center;
    color: var(--cta-color);
    text-transform: uppercase;
    font-size: 12px;
  }
  a:hover circle {
    stroke-dashoffset: 0;
  }
`;

export const CardCTA = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.div`
  font: 11px / 11px ferrari-sans-regular;
  color: var(--main-color);
  width: 90%;
  margin: 32px 32px 16px;
  @media (max-width: 1279px) {
    margin: 32px 32px 16px;
  }
  @media (max-width: 769px) {
    margin: 24px 24px 16px;
  }
  text-transform: uppercase;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  span:first-of-type {
    margin-right: 10px;
    margin-bottom: 2px;
  }
`;

export const SpanCtaIcon = styled.span`
  appearance: none;
  margin: 0px;
  outline: 0px;
  border: 2px solid rgb(239, 237, 237);
  background: transparent;
  display: inline-flex;
  align-items: center;
  height: 20px;
  width: 20px;
  padding: 8px;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
  font-size: 0px;
  margin-left: 10px;
`;

export const CtaCircle = styled.span`
  position: absolute;
  inset: -3px;
  circle {
    stroke: rgb(218, 41, 28);
    stroke-dasharray: 70;
    stroke-dashoffset: 70;
    stroke-width: 1.5px;
    fill: none;
    transition: stroke-dashoffset 0.3s ease 0s;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%) rotate(-90deg);
  }
`;

export const EventDescrWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 32px 8px 32px;
  @media (max-width: 1279px) {
    margin: 0 24px 8px 24px;
  }
`;

export const CtaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  // position: absolute;
  bottom: 32px;
  margin: 26px 0 32px 0;
  @media (max-width: 1279px) {
    margin-top: 16px;
    margin-bottom: 24px;
    position: static;
  }
`;

const actionButtonProperties = (isIntervalDate) => {
  if (isIntervalDate) {
    return `
      width:110px;
      font-size:28px;
  @media (max-width: 1279px) and (min-width: 769px) {
      font-size:24px;
  }
    `;
  }
  return `
      width:60px;
      font-size:36px;
  @media (max-width: 1279px) and (min-width: 769px) {
      font-size:28px;
  }
  `;
};

export const EventDateWrapper = styled.div``;

export const EventDate = styled.div`
  ${(props) => actionButtonProperties(props.isIntervalDate)};
  line-height: 43px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 40px;
`;

export const EvenMonth = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  line-height: 22px;
  letter-spacing: 1.96364px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 22px;
`;

export const EventInfo = styled.div`
  margin-left: 16px;
  overflow: auto;
  a {
    all: revert;
    text-decoration: none;
    color: inherit;
  }
`;
export const EventTitle = styled.div`
  text-transform: uppercase;
  font-size: 13px;
  line-height: 20px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  margin-bottom: 4px;
`;

export const EventSubtitle = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: var(--alternative-grey-color);
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
`;
export const Ewrapper = styled.div``;
