import React from "react";
import { ComponentContainer } from "../../../style";
//components
import InfoGreyMex from "./InfoGreyMex";
import { useSelector } from "react-redux";

export default function WorkLocationMessage({ data }) {
  const bodyPayload = useSelector(
    (state) => state.FormIscrizioneGiftHouse2024.bodyPayload
  );
  return (
    <ComponentContainer>
      {/* Mostra messaggio "spedizione presso il luogo di lavoro" ai dipendenti che selezionano Milano o Altre sedi*/}
      {(bodyPayload?.SedeLavoro === "Milano" ||
        bodyPayload?.SedeLavoro === "Other") && (
        <InfoGreyMex text={data?.giftHouse?.deliveryTxt} />
      )}
    </ComponentContainer>
  );
}
