import { useSelector } from "react-redux";

export default function GetStatusAndErrorData(store) {
  const status = useSelector((state) => state[store].status);
  const errorMessage = useSelector((state) => state[store].errorMessage);
  const errorData = {
    errorCode: status,
    errorMessage: errorMessage,
    btnTxt: "Ritorna alla Homepage",
  };
  return { status, errorData };
}
