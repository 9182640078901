import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./companyComSlice";

const companyComState = (state) => state.companyCom || initialState;

export const selectTabsMap = () =>
  createSelector(companyComState, (state) => state.tabsMap);

export const selectTabCategory = () =>
  createSelector(companyComState, (state) => state.tabCategory);

export const selectDocumentByCategory = (category) =>
  createSelector(companyComState, (state) => {
    return state.documents?.filter((el) => el.subCategory === category) || [];
  });
