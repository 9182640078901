import { takeLatest, put, call } from "redux-saga/effects";
import { axiosCallGet, axiosCallPost } from "../../utils/axiosCalls/AxiosCalls";

import {
  initEventInfo,
  setComponents,
  setError,
} from "./FormIscrizioneGiftHouse2024Slice";

import { showErrorMessage } from "../../utils/notifications/TriggerNotification";

import { FETCH_COMPONENTS, SET_USER_REGISTRATION } from "./constants";

function* fetchComponentsWorker({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    // const result = yield call(() =>
    //   axios.get("/mocks/mock-iscrizione-gift-house-2024.json")
    // );
    yield put(setComponents(result.data.data));
    const eventInfoData = result?.data?.data?.components?.find(
      (el) =>
        el.resourceType === "fi-events/fi-event-subscription-giftHouse-2024"
    );

    if (eventInfoData?.data?.event?.error403) {
      yield put(setError({ status: 403, message: "Unauthorized" }));
      return;
    }

    yield put(initEventInfo(eventInfoData));
  } catch (e) {
    console.error(e);
    yield put(setError(e));
  }
}

export function* SetUserRegistration({ payload }) {
  try {
    const token = payload.token;
    document.body.classList.add("loading");

    yield axiosCallPost(
      `/${payload.lang}/news-and-events/events/subscribe?title=gifthouse`,
      payload.responseBody,
      token,
      false,
      false
    );
    window.location.replace(payload.path);
  } catch (e) {
    const messageError =
      payload.lang === "it" ? "Operazione fallita" : "Operation failed";
    showErrorMessage(messageError);
  } finally {
    document.body.classList.remove("loading");
  }
}

export default function* FormAnnuncioSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchComponentsWorker);
  yield takeLatest(SET_USER_REGISTRATION, SetUserRegistration);
}
