import React from "react";
import { DetailContainerData, DetailDataLabel } from "./style";
import { useSelector } from "react-redux";
import { QrCode } from "../qrCode/QrCode";
import { Divider, GenericTitle, TitleForm } from "../style";

export function DetailInfoGiftHouse({ data, isRecapPage }) {
  const lang = useSelector((state) => state.coreComponents.language);
  const appointmentField =
    data?.subscription?.primaryAppointment?.configurations
      ?.filter((el) => el?.groupName === "deliveryMode")
      .find(
        (el) =>
          el.fieldValue === "shipmentMilano" ||
          el.fieldValue === "shipmentOther" ||
          el.fieldValue === "deliveryMaranello" ||
          el.fieldValue === "deliveryModena"
      )?.fieldValue;

  const typeOfEvent = () => {
    let text;
    switch (appointmentField) {
      case "shipmentMilano":
        text = data?.giftHouse?.shipmentMilanoLbl;
        break;
      case "shipmentOther":
        text = data?.giftHouse?.shipmentOthersLbl;
        break;
      case "deliveryMaranello":
        text = data?.giftHouse?.collectAtMaranelloLbl;
        break;
      case "deliveryModena":
        text = data?.giftHouse?.collectAtModenaLbl;
        break;
      default:
        text = null;
        break;
    }
    return text;
  };
  const getSubscriptionTime = (startDate, endDate, lang) => {
    let result = new Date(startDate)?.toLocaleString(lang, {
      hour: "2-digit",
      minute: "2-digit",
    });
    result = `${result} - ${new Date(endDate)?.toLocaleString(lang, {
      hour: "numeric",
      minute: "2-digit",
    })}`;
    return result;
  };

  const getSubscriptionDay = (startDate, lang) => {
    let result = new Date(startDate)?.toLocaleString(lang, {
      weekday: "long",
      day: "2-digit",
      month: "long",
    });
    return result.charAt(0).toUpperCase() + result.slice(1);
  };
  function generateHtml(argument) {
    return { __html: argument };
  }

  const isQrCodeAndDateVisible = () => {
    return appointmentField === "deliveryMaranello" ||
      appointmentField === "deliveryModena"
      ? true
      : false;
  };

  const showQrCodeAndDate = isQrCodeAndDateVisible();
  return (
    <>
      {showQrCodeAndDate && (
        <GenericTitle>
          <QrCode data={data?.subscription?.qrCode} />
          <p
            dangerouslySetInnerHTML={generateHtml(
              `${data?.giftHouse?.richText}`
            )}
          />
        </GenericTitle>
      )}
      {showQrCodeAndDate && !isRecapPage && <Divider />}
      <TitleForm>{data?.giftHouse?.title}</TitleForm>
      <DetailContainerData>
        <DetailDataLabel>{data?.giftHouse?.typeLabel}</DetailDataLabel>
        <p>{typeOfEvent()}</p>
      </DetailContainerData>
      {showQrCodeAndDate && (
        <>
          <DetailContainerData>
            <DetailDataLabel>{data?.giftHouse?.dateLabel}</DetailDataLabel>
            <p>
              {getSubscriptionDay(
                data?.subscription?.primaryAppointment?.appointmentStartDate,
                lang
              )}
            </p>
          </DetailContainerData>
          <DetailContainerData>
            <DetailDataLabel>{data?.giftHouse?.slotLabel}</DetailDataLabel>
            <p>
              {getSubscriptionTime(
                data?.subscription?.primaryAppointment?.appointmentStartDate,
                data?.subscription?.primaryAppointment?.appointmentEndDate,
                lang
              )}
            </p>
          </DetailContainerData>
        </>
      )}
      <DetailContainerData>
        <DetailDataLabel>{data?.giftHouse?.locationLabel}</DetailDataLabel>
        <p>{data?.subscription?.primaryAppointment?.locationTxt}</p>
      </DetailContainerData>
    </>
  );
}

export default React.memo(DetailInfoGiftHouse);
