import { Container, SendButton, GenericTitle } from "./style";
import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setBodyPayload,
  setNotRequired,
  setShowThankYouPage,
} from "../../../pages/FormContraffazioni/FormContraffazioniSlice";

import Hero from "./components/Hero/Hero";
import ComponentReport from "./components/ComponentReport";
import ComponentUploadFile from "./components/ComponentUploadFile";
import ComponentGift from "./components/ComponentGift";
import ComponentPrivacy from "./components/ComponentPrivacy/ComponentPrivacy";
import { axiosCallPost } from "../../../utils/axiosCalls/AxiosCalls";
import ThankYouPage from "./components/ThankYouPage";

function LayoutFormContraffazione({ data, heroData }) {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  //selectors
  const token = useSelector((state) => state.login.activeToken);
  const { language } = useSelector((state) => state.coreComponents);
  const bodyPayload = useSelector(
    (state) => state.FormContraffazioni.bodyPayload
  );
  const notRequired = useSelector(
    (state) => state.FormContraffazioni.notRequired
  );
  const checkedConsents = useSelector(
    (state) => state.FormContraffazioni.checkedConsents
  );
  const currentPlace = useSelector(
    (state) => state.FormContraffazioni.currentPlace
  );
  const showThankYouPage = useSelector(
    (state) => state.FormContraffazioni.showThankYouPage
  );

  //useState
  const [allowed, setAllowed] = useState(null);
  const [buttonHasBeenClicked, setButtonHasBeenClicked] = useState(false);
  const [files, setFiles] = useState([]);

  const privacyPoliciesCheck = () => {
    let result = true;
    if (data?.policiesRules?.items) {
      result =
        checkedConsents?.length === data?.policiesRules?.items?.length &&
        !checkedConsents?.find((el) => el.checked === false);
    }
    return result;
  };

  const isSubmitActive =
    !Object.values(bodyPayload).includes("") &&
    !Object.values(currentPlace).includes("") &&
    bodyPayload?.telephone?.length > 4 &&
    files?.length > 0 &&
    privacyPoliciesCheck();

  //functions
  function generateHtml(argument) {
    return { __html: argument };
  }

  const handleEventInfo = (e) => {
    const { name, value } = e.currentTarget;
    if (
      name === "typeProduct" ||
      name === "note" ||
      name === "numberProducts"
    ) {
      dispatch(
        setNotRequired({
          ...notRequired,
          [name]: value,
        })
      );
    } else {
      dispatch(
        setBodyPayload({
          ...bodyPayload,
          [name]: value,
        })
      );
    }
  };

  async function submitForm(e) {
    e.preventDefault();
    const body = new FormData(formRef.current);
    const addressBuilded = `${body.get("address")} ${body.get(
      "cap"
    )} ${body.get("city")} ${body.get("region")}`;
    body.delete("address");
    body.append("address", addressBuilded);
    body.delete("onSearchAddress");
    body.delete("region");
    body.delete("city");
    body.delete("cap");

    files.forEach((el) => {
      body.append("files", el.file);
    });

    if (isSubmitActive) {
      body.append("privacyPolicy", "true");
    }
    try {
      setButtonHasBeenClicked(true);
      if (isSubmitActive) {
        setAllowed(true);
        document.body.classList.add("loading");
        const path = `/${language}/tools/anticounterfeiting`;
        await axiosCallPost(path, body, token, false, false);
        document.body.classList.remove("loading");
        dispatch(setShowThankYouPage(true));
      } else {
        setAllowed(false);
        const element =
          document.getElementById("required")?.previousElementSibling;
        if (element)
          element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    } catch (e) {
      console.error(e);
      document.body.classList.remove("loading");
    }
  }

  useEffect(() => {
    const element = document.getElementById("required")?.previousElementSibling;
    if (buttonHasBeenClicked) {
      element &&
        !allowed &&
        element?.scrollIntoView({ behavior: "smooth", block: "center" });
      setButtonHasBeenClicked(false);
    }
  }, [allowed]);

  return showThankYouPage ? (
    <ThankYouPage data={data?.thankYouPage} />
  ) : (
    <>
      {heroData?.fileReference && <Hero data={heroData} />}
      <Container>
        <GenericTitle>
          <h2>{data?.title}</h2>
          <p dangerouslySetInnerHTML={generateHtml(`${data?.richText}`)} />
        </GenericTitle>
        <form
          onSubmit={(e) => {
            submitForm(e);
          }}
          ref={formRef}
        >
          <ComponentReport
            data={data}
            bodyPayload={bodyPayload}
            notRequired={notRequired}
            allowed={allowed}
            handleEventInfo={handleEventInfo}
          />
          <ComponentUploadFile
            data={data}
            allowed={allowed}
            setFiles={setFiles}
            files={files}
          />
          <ComponentGift
            data={data}
            bodyPayload={bodyPayload}
            allowed={allowed}
            handleEventInfo={handleEventInfo}
          />
          <ComponentPrivacy data={data} allowed={allowed} />
          <SendButton
            className={
              isSubmitActive ? "submitButton activeSubmit" : "submitButton"
            }
          >
            {data?.submitButton?.submitLbl}
          </SendButton>
        </form>
      </Container>
    </>
  );
}

export default LayoutFormContraffazione;
