import {
  ComponentContainer,
  ContainerInfo,
  InfoCircle,
  InfoIcon,
  InfoImg,
  InputSection,
} from "../style";
import ErrorField from "./ErrorField/ErrorField";
import { Tooltip } from "react-tooltip";

export default function ComponentPersonalData({
  data,
  bodyPayload,
  allowed,
  handleEventInfo,
}) {
  return (
    <>
      <ComponentContainer>
        <h3>{data?.personalDataTxt} </h3>
        <InputSection hideLabel={true}>
          <input
            autoComplete="nope"
            id="firstname"
            name="firstname"
            placeholder={data?.nameLbl}
            defaultValue={bodyPayload?.firstname}
            type="text"
            onChange={(e) => {
              handleEventInfo(e);
            }}
          />
          <label htmlFor="firstname">{data?.nameLbl}</label>
        </InputSection>
        {!bodyPayload?.firstname && allowed === false && (
          <ErrorField text={data?.errorMessage} />
        )}
      </ComponentContainer>
      <ComponentContainer>
        <InputSection hideLabel={true}>
          <input
            autoComplete="nope"
            id="lastname"
            name="lastname"
            placeholder={data?.surnameLbl}
            type="text"
            onChange={(e) => {
              handleEventInfo(e);
            }}
          />
          <label htmlFor="lastname">{data?.surnameLbl}</label>
        </InputSection>
        {!bodyPayload?.lastname && allowed === false && (
          <ErrorField text={data?.errorMessage} />
        )}
      </ComponentContainer>
      <ComponentContainer>
        <InputSection hideLabel={true}>
          <input
            autoComplete="nope"
            id="email"
            name="email"
            placeholder={data?.mailLbl}
            type="email"
            onChange={(e) => {
              handleEventInfo(e);
            }}
          />
          <label htmlFor="typeProduct">{data?.mailLbl}</label>
        </InputSection>
        {!bodyPayload?.email && allowed === false && (
          <ErrorField text={data?.errorMessage} />
        )}
      </ComponentContainer>
      <ComponentContainer>
        <InputSection hideLabel={true}>
          <input
            autoComplete="nope"
            id="subjectCode"
            name="subjectCode"
            placeholder={data?.subjectCodeLbl}
            onChange={(e) => {
              handleEventInfo(e);
            }}
          />
          <label htmlFor="note">{data?.subjectCodeLbl}</label>
          <ContainerInfo
            data-tooltip-id="subjectCodeTip"
            data-tooltip-html="Es. dipendente 000000000000XXXX </br> </br> Es. consulente E_0000000XXXX"
          >
            <InfoIcon>
              <InfoCircle />
              <InfoImg />
            </InfoIcon>
          </ContainerInfo>
          <Tooltip id="subjectCodeTip" place="top" />
        </InputSection>
        {!(
          /^E_\d{11}$/.test(bodyPayload?.subjectCode) ||
          /^0\d{15}$/.test(bodyPayload?.subjectCode)
        ) &&
          allowed === false && <ErrorField text={data?.errorMessage} />}
      </ComponentContainer>
    </>
  );
}
