import styled from "styled-components";

export const DocumentsWrapper = styled.div`
  width: 100%;
  .doc-container {
    margin: 0;
  }
  .doc-container:last-child {
    border: 0;
  }
  .doc-title-and-data {
    margin-left: 0;
  }
  .doc-title-and-data h4 {
    margin-top: 0;
  }
`;

export const DocContainer = styled.div`
  margin: 0px !important;
  img {
    display: none;
  }
  path {
    fill: var(--main-color);
  }
  @media screen and (min-width: 1280px) {
    border-bottom: 1px solid rgb(212, 210, 210);
    margin: 96px 0px;
    img {
      display: block;
    }
  }
  @media screen and (max-width: 1279px) {
    margin: 32px 0px;
  }
`;

export const DocContainerTitle = styled.div`
  margin-bottom: 32px;
  color: var(--main-color);
  text-transform: uppercase;
  font-size: 18px;
  @media screen and (min-width: 1280px) {
    font-size: 12px;
  }
`;
