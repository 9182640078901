import { takeLatest, put, call, select } from "redux-saga/effects";
import { axiosCallGet, axiosCallPost } from "../../utils/axiosCalls/AxiosCalls";

import {
  FETCH_COMPONENTS,
  FETCH_NOTIFICATIONS,
  SEND_READ_NOTIFICATIONS,
  SEND_READ_ALL_NOTIFICATIONS,
} from "./constants";
import { notificationActions } from "./notificationSlice";
import { selectNotificationCatalogue } from "./selectors";

function* fetchComponents({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    yield put(notificationActions.setComponents(result.data.data));
    const catalogue = yield select(selectNotificationCatalogue());
    yield put(notificationActions.setNotificationsArray(catalogue?.items));
  } catch (e) {
    console.error(e);
    yield put(notificationActions.setError(e));
  }
}

function* fetchNotifications({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    yield put(notificationActions.setNotificationsArray(result.data.results));
  } catch (e) {
    console.error(e);
    yield put(notificationActions.setError(e));
  }
}

function* sendReadAllNotifications({ payload }) {
  try {
    yield axiosCallPost(
      `/${payload.coreLang}/notify`,
      { markAllAsRead: true },
      payload.token
    );
    yield call(() =>
      fetchNotifications({
        payload: {
          path: `/${payload.coreLang}/notify`,
          token: payload.token,
        },
      })
    );
  } catch (e) {
    console.error(e);
    yield put(notificationActions.setError(e));
  }
}

function* sendReadNotifications({ payload }) {
  try {
    yield axiosCallPost(
      `/${payload.coreLang}/notify`,
      { keys: payload.currentDocumentKeys },
      payload.token
    );
    yield call(() =>
      fetchNotifications({
        payload: {
          path: `/${payload.coreLang}/notify`,
          token: payload.token,
        },
      })
    );
  } catch (e) {
    console.error(e);
    yield put(notificationActions.setError(e));
  }
}

export default function* notificationSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchComponents);
  yield takeLatest(FETCH_NOTIFICATIONS, fetchNotifications);
  yield takeLatest(SEND_READ_NOTIFICATIONS, sendReadNotifications);
  yield takeLatest(SEND_READ_ALL_NOTIFICATIONS, sendReadAllNotifications);
}
