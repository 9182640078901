import styled from "styled-components";

export const Wrapper = styled.div`
  width: -webkit-fill-available;
  iframe {
    width: 100%;
  }
`;

export const ContainerFullHeight = styled.div`
  iframe {
    height: ${(props) => props.iframeHeight};
    display: block; /* iframes are inline by default */
    width: 100%;
    max-height: none !important;
  }
`;
