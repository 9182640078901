import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: -40px;
  height: 307px;
  color: #707070;
  padding: 0px;
  gap: 10px;
  overflow: auto;
  @media (max-width: 1279px) {
    max-width: 100%;
    height: 259px;
  }
`;
export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
  gap: 20px;
  overflow: auto;
  @media (max-width: 1279px) {
    width: 100%;
  }
`;
export const NoContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 80px;
  height: 100px;
  border-radius: 5px;
  background: var(--alternative-bg-color);
`;
export const FakeRow = styled.div`
  width: 70px;
  height: 7px;
  border-radius: 5px;
  background: #8f8f8f52;
`;
export const X = styled.div`
  width: 130px;
  height: 2px;
  background: #ff000085;
  position: absolute;
  transform: rotate(40deg);
`;
export const X2 = styled.div`
  width: 130px;
  height: 2px;
  background: #ff000085;
  position: absolute;
  transform: rotate(-40deg);
`;
