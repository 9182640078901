import { createSlice } from "@reduxjs/toolkit";

export const RECAP_ISCRIZIONE_GH_2024 = "RecapAndDetailGiftHouse2024";

export const initialState = {
  components: {},
  status: null,
  errorMessage: "",
};

const RecapAndDetailGiftHouse2024Slice = createSlice({
  name: RECAP_ISCRIZIONE_GH_2024,
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setStatusNull(state) {
      state.status = null;
    },
    resetRecapAndDetailGiftHouse2024: () => initialState,
  },
});

export const RecapAndDetailGiftHouse2024Actions =
  RecapAndDetailGiftHouse2024Slice.actions;
export default RecapAndDetailGiftHouse2024Slice;
