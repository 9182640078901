import {
  Container,
  InputSection,
  SendButton,
  InputSectionCheckbox,
  ImageContainer,
} from "./style";
import React, { useRef, useState, useEffect, useCallback } from "react";
import CustomSelect from "../customSelect/CustomSelect";
import { Text } from "../news/newsDetailContainer/newsText/style";
import { ImgHeader } from "../strumenti/style";
import MyDropzone from "../dropzone/Dropzone";
import getData from "../../../utils/getData";
import { DateTime } from "luxon";
import DroppableImages from "./droppableImages/DroppableImages";
import { useDispatch, useSelector } from "react-redux";
import { axiosCallPost } from "../../../utils/axiosCalls/AxiosCalls";
import { showErrorMessage } from "../../../utils/notifications/TriggerNotification";
import { useLocation, useNavigate } from "react-router-dom";
import PageLoader from "../pageLoader/PageLoader";
import DatePickerCustom from "../datePickerCustom/datePickerCustom";
import { addDays } from "date-fns"; // test per il datepicker
import { setOverallFileSize } from "../../../pages/FormAnnuncio/FormAnnuncioSlice";
import * as DOMPurify from "dompurify";

function LayoutFormAnnunci({ data, heroImg, detail, id }) {
  function html() {
    return { __html: data.richText };
  }
  const dispatch = useDispatch();
  const location = useLocation();
  const token = useSelector((state) => state.login.activeToken);
  const dateTime6Months = DateTime.now().plus({ months: 6 }).toISODate();
  const [enableButton, setEnableButton] = useState(false);
  const mainImgRef = useRef(null);
  const formRef = useRef(null);
  const [category, setCategory] = useState(Object.keys(data.categories)[0]);
  const [loading, setLoading] = useState(detail);
  const [coverClassName, setCoverClassName] = useState("dropzone-small");
  const [cover, setCover] = useState(null);
  const [coverSrc, setCoverSrc] = useState("");
  const [displayCover, setDisplayCover] = useState("none");
  const [oiClassName, setOiClassName] = useState("dropzone-large");
  const [otherImages, setOtherImages] = useState([]);
  const [adDate, setAdDate] = useState(
    detail ? detail.expiration : dateTime6Months
  );
  const navigate = useNavigate();
  function openImage(e) {
    const target = e.target;
    target.parentNode.querySelector(".image-overlay").style.display = "flex";
  }
  function closeImage(e) {
    const target = e.target;
    target.parentNode.style.display = "none";
  }
  function isFormAble(isCover, isCoverNull) {
    const formData = new FormData(formRef.current);
    const everyFieldFilled = [...formData.entries()].every((v) => v[1]);
    const isCategoryFilled = formRef.current["category"].value;
    const isOfferTypeFilled = formRef.current["offerType"].value;
    const responsabilita = formData.get("responsabilita") === "on";
    setEnableButton(
      everyFieldFilled &&
        isCategoryFilled &&
        isOfferTypeFilled &&
        responsabilita
    );
  }
  function onCoverChange(file, isSubstitution) {
    if (isSubstitution) {
      dispatch(setOverallFileSize(-cover.size));
    }
    return new Promise((res, rej) => {
      setDisplayCover("block");
      mainImgRef.current.onload = () => {
        URL.revokeObjectURL(mainImgRef.src);
        setCoverClassName("dropzone-small-covered");
        setCover(file);
        isFormAble(true);
        res();
      };
      //mainImgRef.current.src = URL.createObjectURL(file);
      setCoverSrc(URL.createObjectURL(file));
    });
  }
  function onOtherImagesChange(file) {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        const base64data = reader.result;
        setOtherImages((oldArray) => [
          ...oldArray,
          { base64data, id: oldArray.length + 1, file },
        ]);
        setOiClassName("dropzone-small");
        res();
      };
    });
  }
  async function submitForm(e) {
    e.preventDefault();
    const body = new FormData(formRef.current); // "72"
    const secureVal = DOMPurify.sanitize(body.get("description"));
    body.set("description", secureVal);
    body.append("cover", cover);
    if (otherImages.length) {
      otherImages.forEach((v, i) => {
        body.append("gallery", v.file);
      });
    }
    body.delete("responsabilita");
    try {
      document.body.classList.add("loading");
      const path = id ? "/ad_board/" + id : "/ad_board";
      await axiosCallPost(path, body, token, true, false);
      document.body.classList.remove("loading");
      const searchParams = new URLSearchParams(location.search);
      navigate(searchParams.get("path"));
    } catch (e) {
      console.error(e);
      document.body.classList.remove("loading");
      showErrorMessage("ko");
    }
  }
  useEffect(() => {
    if (detail?.cover) {
      (async () => {
        try {
          try {
            var blob = await fetch(detail.cover).then((r) => r.blob());
            if (detail.gallery?.length) {
              for (let i = 0; i < detail.gallery.length; i++) {
                let blobI = await fetch(detail.gallery[i]).then((r) =>
                  r.blob()
                );
                await onOtherImagesChange(blobI);
              }
            }
          } catch (e) {
            console.error(e);
          }
          setCoverSrc(detail.cover);
          setDisplayCover("block");
          setCover(blob || true); // test only
          setCoverClassName("dropzone-small-covered");
          setLoading(false);
        } catch (e) {
          setLoading(false);
        }
      })();
    } else setLoading(false);
  }, [detail]);
  const typeOfferType =
    category === "lookingForHouse" || category === "sellHouse"
      ? data.types
      : data.categoriesObject;
  return (
    <>
      {!loading ? (
        <>
          <ImgHeader src={heroImg} />
          <Container>
            <h2>{data.title}</h2>
            <Text dangerouslySetInnerHTML={html()} />
            <form ref={formRef} onSubmit={submitForm}>
              <CustomSelect
                hideLabel={true}
                onChange={(e) => {
                  setCategory(e.target.value);
                  isFormAble();
                }}
                name="category"
                defaultValue={detail ? detail.category : ""}
                placeholderString={"Tipologia"}
              >
                {data.categories.map((v) => (
                  <option value={v.key}>{v.label}</option>
                ))}
              </CustomSelect>
              <CustomSelect
                hideLabel={true}
                onChange={(e) => {
                  isFormAble();
                }}
                name="offerType"
                defaultValue={detail ? detail.offerType : ""}
                placeholderString={"Tipo"}
              >
                {typeOfferType.map((v) => (
                  <option value={v.key}>{v.label}</option>
                ))}
              </CustomSelect>
              <InputSection hideLabel={true}>
                <input
                  type="text"
                  name="title"
                  placeholder={data.adTitle}
                  onChange={() => isFormAble()}
                  defaultValue={detail ? detail.title : ""}
                />
                <label>{data.adTitle}</label>
              </InputSection>
              <textarea
                placeholder={data.adDescription}
                rows={10}
                name="description"
                onChange={() => isFormAble()}
                defaultValue={detail ? detail.description : ""}
              />
              <InputSection hideLabel={true}>
                <input
                  type="text"
                  name="city"
                  onChange={() => isFormAble()}
                  placeholder={data.municipalityLabel}
                  defaultValue={detail ? detail.city : ""}
                />
                <label>{data.municipalityLabel}</label>
              </InputSection>
              <InputSection hideLabel={true}>
                <input
                  type="text"
                  name="address"
                  onChange={() => isFormAble()}
                  placeholder={data.addressLabel}
                  defaultValue={detail ? detail.address : ""}
                />
                <label>{data.addressLabel}</label>
              </InputSection>
              {(category === "lookingForHouse" ||
                category === "sellHouse" ||
                category === "sellObject") && (
                <InputSection hideLabel={true}>
                  <input
                    type="number"
                    name="price"
                    placeholder={data.priceLabel}
                    onChange={() => isFormAble()}
                    defaultValue={detail ? detail.priceLabel : ""}
                  />
                  <label>{data.priceLabel}</label>
                </InputSection>
              )}
              <InputSection>
                <ImageContainer style={{ display: displayCover }}>
                  <img ref={mainImgRef} src={coverSrc} />
                  <img
                    src="/img/dots.svg"
                    className="form-annuncio-dots"
                    onClick={openImage}
                  />
                  <div className="image-overlay">
                    <img
                      src="/img/close-community-image.svg"
                      onClick={closeImage}
                    />
                    <span
                      onClick={(e) => {
                        setCover(null);
                        closeImage(e);
                        setDisplayCover("none");
                        dispatch(setOverallFileSize(-cover.size));
                        isFormAble(false, true);
                      }}
                    >
                      Cancella immagine
                    </span>
                  </div>
                </ImageContainer>
                {!cover && (
                  <MyDropzone
                    onChange={onCoverChange}
                    className={"dropzone-small"}
                    placeholder={data.coverImageLabel}
                    multiple={false}
                  />
                )}
                <label>{data.coverImageLabel}</label>
              </InputSection>
              <InputSection>
                <DroppableImages
                  otherImages={otherImages}
                  oiClassName={oiClassName}
                  otherImagesLabel={data.otherImagesLabel}
                  onOtherImagesChange={onOtherImagesChange}
                  setOtherImages={setOtherImages}
                  setCover={setCover}
                  setDisplayCover={setDisplayCover}
                  setOiClassName={setOiClassName}
                  onCoverChange={onCoverChange}
                  isFormAble={isFormAble}
                />
                <label>{data.otherImagesLabel}</label>
              </InputSection>
              <InputSection hideLabel={true}>
                <input
                  type="text"
                  name="phone"
                  onChange={() => isFormAble()}
                  placeholder={data.telephoneLabel}
                  defaultValue={detail ? detail.phone : ""}
                />
                <label>{data.telephoneLabel}</label>
              </InputSection>
              <InputSection hideLabel={true}>
                <input
                  type="text"
                  name="email"
                  placeholder={data.emailLabel}
                  onChange={() => isFormAble()}
                  defaultValue={detail ? detail.email : ""}
                />
                <label>{data.emailLabel}</label>
              </InputSection>
              <InputSection hideLabel={false}>
                {/*<input
                  type="date"
                  name="expiration"
                  placeholder={data.deadline}
                  min={getData()}
                  onChange={() => isFormAble()}
                  defaultValue={detail ? detail.expiration : dateTime6Months}
                />*/}
                <DatePickerCustom
                  startDate={adDate}
                  setStartDate={setAdDate}
                  selected={adDate}
                  onChange={() => isFormAble()}
                  inputName="expiration"
                />
                {/*maxDate={addDays(new Date(), 7)}*/}
                <label>{data.deadline}</label>
              </InputSection>
              <InputSectionCheckbox>
                <input
                  type="checkbox"
                  className="checkbox-input"
                  onChange={() => isFormAble()}
                  name="responsabilita"
                />
                <span className="">{data.responsibilityCheckLabel}</span>
              </InputSectionCheckbox>
              <SendButton disabled={!enableButton}>
                {data.buttonLabel}
              </SendButton>
            </form>
          </Container>
        </>
      ) : (
        <PageLoader />
      )}
    </>
  );
}

export default LayoutFormAnnunci;
