import {
  FETCH_COMPONENTS,
  FETCH_NOTIFICATIONS,
  SEND_READ_NOTIFICATIONS,
  SEND_READ_ALL_NOTIFICATIONS,
} from "./constants";

export const fetchComponents = (payload) => ({
  type: FETCH_COMPONENTS,
  payload,
});

export const fetchNotifications = (payload) => ({
  type: FETCH_NOTIFICATIONS,
  payload,
});

export const sendReadNotifications = (payload) => ({
  type: SEND_READ_NOTIFICATIONS,
  payload,
});

export const sendReadAllNotifications = (payload) => ({
  type: SEND_READ_ALL_NOTIFICATIONS,
  payload,
});
