import { Container, TitleForm, GenericTitle, RecapContainer } from "./style";
import { ButtonsCta } from "./buttonsCta/buttonsCta";
import DetailInfoGiftHouse from "./detailsInfo/DetailInfoGiftHouse";

export function LayoutRecapAndDetailGiftHouse2024({
  data,
  isRecapPage,
  buttonsData,
}) {
  const fullName = `${data?.subscription?.name} ${data?.subscription?.lastname}`;
  const userFullName = fullName?.replace(
    /\w+/g,
    (w) => w[0].toUpperCase() + w.slice(1).toLowerCase()
  );
  function generateHtml(argument) {
    return { __html: argument };
  }

  return (
    <Container detail={isRecapPage}>
      <GenericTitle>
        <h5>{data?.title}</h5>
      </GenericTitle>
      {isRecapPage && (
        <RecapContainer detail={isRecapPage}>
          <GenericTitle>
            <p
              dangerouslySetInnerHTML={generateHtml(
                `${userFullName} ${data?.description}`
              )}
            />
          </GenericTitle>
        </RecapContainer>
      )}
      <RecapContainer detail={isRecapPage}>
        <DetailInfoGiftHouse isRecapPage={isRecapPage} data={data} />
      </RecapContainer>
      <ButtonsCta
        data={buttonsData}
        pageData={data}
        isRecapPage={isRecapPage}
      />
    </Container>
  );
}
