import { useDispatch } from "react-redux";
import CustomSelect from "../../../customSelect/CustomSelect";
import { ComponentContainer, TextDescr } from "../../style";
//components
import ErrorField from "../ErrorField/ErrorField";
import { useState, useEffect } from "react";
import { setBodyPayload } from "../../../../../pages/FormIscrizioneGiftHouse2024/FormIscrizioneGiftHouse2024Slice";

export default function RitiroGift({ data, allowed, bodyPayload }) {
  const [slot, setSlot] = useState("");
  const [placeholderString, setPlaceholderString] = useState(
    data?.giftHouse?.slotLabel
  );
  const dispatch = useDispatch();

  const appointmentsSede =
    bodyPayload?.SedeLavoro === "Modena"
      ? "deliveryModena"
      : "deliveryMaranello";

  // const Appointments = data?.event?.appointments?.filter((appointment) =>
  //   appointment?.slots?.find((slot) =>
  //     slot?.configurations?.find(
  //       (el) =>
  //         el.groupName === "deliveryMode" && el.fieldValue === appointmentsSede
  //     )
  //   )
  // );

  const ParseAppointmentsBySede = () => {
    let result = [];
    data?.event?.appointments?.forEach((appointment) => {
      let slotsOnTarget = [];
      appointment?.slots?.forEach((slot) => {
        let configFound = slot?.configurations?.find(
          (el) =>
            el.groupName === "deliveryMode" &&
            el.fieldValue === appointmentsSede
        );
        configFound && slotsOnTarget.push(slot);
      });
      if (slotsOnTarget?.length > 0) {
        let filteredAppointment = { ...appointment };
        filteredAppointment.slots = slotsOnTarget;
        result.push(filteredAppointment);
      }
    });

    return result;
  };

  const Appointments = ParseAppointmentsBySede();
  const existSlots = Appointments?.length > 0;

  const handleAppointmentId = (e) => {
    const { value } = e.target;
    dispatch(setBodyPayload({ ...bodyPayload, appointmentId: +value }));
  };

  useEffect(() => {
    if (existSlots && bodyPayload?.appointmentId) {
      let currentGroupSelected = data?.event?.appointments?.find(
        (appointment) =>
          appointment?.slots?.find(
            (slot) => slot?.id === bodyPayload?.appointmentId
          )
      );

      setPlaceholderString(currentGroupSelected?.title);
    } else {
      setPlaceholderString(data?.giftHouse?.slotLabel);
    }
  }, [existSlots, bodyPayload?.appointmentId]);

  useEffect(() => {
    setSlot("");
  }, [bodyPayload.SedeLavoro]);
  return (
    <>
      <ComponentContainer>
        {bodyPayload?.SedeLavoro !== null &&
          (bodyPayload?.SedeLavoro === "Maranello" ||
            bodyPayload?.SedeLavoro === "Modena") && (
            <>
              <h3>{data?.giftHouse?.titleGetGift} </h3>
              <TextDescr>{data?.giftHouse?.descrGetGift}</TextDescr>
            </>
          )}
      </ComponentContainer>
      {(bodyPayload?.SedeLavoro === "Maranello" ||
        bodyPayload?.SedeLavoro === "Modena") && (
        <ComponentContainer>
          <CustomSelect
            name="appointmentId"
            defaultValue={bodyPayload?.appointmentId || slot}
            setValue={setSlot}
            placeholderString={placeholderString}
            onChange={handleAppointmentId}
          >
            {Appointments &&
              Appointments?.map((appointment, ind) => {
                return (
                  <optgroup
                    key={"appointment" + ind}
                    label={appointment?.title}
                  >
                    {appointment?.slots?.map((slot) => {
                      return (
                        <option
                          checked={
                            bodyPayload?.appointmentId
                              ? bodyPayload?.appointmentId === slot?.id
                              : null
                          }
                          className="data-evento"
                          value={slot?.id}
                          key={slot?.id}
                          code={data?.event?.code}
                        >
                          {slot?.appointmentStartDate.slice(11, 16)} -{" "}
                          {slot?.appointmentEndDate.slice(11, 16)}
                        </option>
                      );
                    })}
                  </optgroup>
                );
              })}
          </CustomSelect>
          {bodyPayload?.appointmentId == null && allowed === false && (
            <ErrorField text={data?.giftHouse?.errorMessage} />
          )}
        </ComponentContainer>
      )}
    </>
  );
}
