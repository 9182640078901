import { createSlice } from "@reduxjs/toolkit";

export const FORM_ANNUNCIO_SCOPE = "FormAnnuncio";

export const initialState = {
  components: {},
  status: null,
  errorMessage: "",
  detail: null,
  overallFileSize: 0,
};

const FormAnnuncioSlice = createSlice({
  name: FORM_ANNUNCIO_SCOPE,
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
      state.detail = null;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setOverallFileSize(state, action) {
      state.overallFileSize += action.payload;
    },
    setStatusNull(state) {
      state.status = null;
    },
    fetchComponents: () => {},
    getDetail: () => {},
    setDetail(state, action) {
      state.status = 200;
      const detailRaw = action.payload;
      state.detail = detailRaw.components.find(
        (v) => v.resourceType === "fi-ads/fi-ads-item-placeholder"
      ).data.item;
    },
    saveAd: () => {},
    resetFormDataAnnuncio: () => initialState,
  },
});

export const {
  setComponents,
  setError,
  setOverallFileSize,
  setStatusNull,
  fetchComponents,
  saveAd,
  getDetail,
  setDetail,
  resetFormDataAnnuncio,
} = FormAnnuncioSlice.actions;
export default FormAnnuncioSlice;
