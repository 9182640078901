import styled from "styled-components";

export const GreyPopup = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 16px;
  border-radius: 8px;
  margin-top: 30px;
  ${(props) =>
    props.color !== "dark"
      ? `background-color: #EFEDED`
      : `background-color: var(--alternative-bg-color)`};
  svg {
    min-width: 24px;
    height: 24px;
  }
  p {
    font-family: ferrari-sans-regular, sans-serif;
    font-weight: 200;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1px;
    margin: 0;
  }
`;

export const InfoIcon = styled.svg.attrs({
  width: "24",
  height: "24",
  viewBox: "0 0 26 26",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
})`
  pointer-events: none;
`;
export const InfoCircle = styled.path.attrs({
  fillRule: "evenodd",
  d: "M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12.0031 22.8031C17.9678 22.8031 22.8031 17.9678 22.8031 12.0031C22.8031 6.03845 17.9678 1.20312 12.0031 1.20312C6.03845 1.20312 1.20312 6.03845 1.20312 12.0031C1.20312 17.9678 6.03845 22.8031 12.0031 22.8031Z",
})`
  fill: #d4d2d2;
  pointer-events: none;
`;
export const InfoImg = styled.path.attrs({
  fillRule: "evenodd",
  d: "M12.7992 11.2016V16.8016H11.1992V12.8016C11.1992 11.9447 11.8728 11.2451 12.7194 11.2035L12.7992 11.2016ZM11.9992 8C12.441 8 12.7992 8.35817 12.7992 8.8C12.7992 9.24183 12.441 9.6 11.9992 9.6C11.5574 9.6 11.1992 9.24183 11.1992 8.8C11.1992 8.35817 11.5574 8 11.9992 8Z",
})`
  fill: var(--main-color);
`;
