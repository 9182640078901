import React from "react";
import { ComponentContainer } from "../../../style";
//components
import InfoGreyMex from "./InfoGreyMex";
import { useSelector } from "react-redux";

export default function CollectGiftMessages({ data }) {
  const bodyPayload = useSelector(
    (state) => state.FormIscrizioneGiftHouse2024.bodyPayload
  );

  const haveChildsInAge = bodyPayload?.childsInAge?.length > 0;
  const childsInAgeSubscribed =
    bodyPayload?.childrenInAgeSubscribed?.length > 0;
  const childsRegisteredByConsort = bodyPayload?.childrenInAgeSubscribed?.find(
    (e) => e?.subscriptionInfo?.subscribedBy !== bodyPayload?.userFiscalCode
  )
    ? true
    : false;
  const isShipment =
    bodyPayload?.SedeLavoro === "Milano" || bodyPayload?.SedeLavoro === "Other"
      ? true
      : false;
  return (
    <ComponentContainer>
      {/* Mostra messaggio informativo a chiunque abbia dei figli intitolati a ricevere il gift*/}
      {bodyPayload.SedeLavoro &&
        !isShipment &&
        haveChildsInAge &&
        !childsRegisteredByConsort && (
          <InfoGreyMex text={data?.giftHouse?.collectAdditionalGiftTxt} />
        )}
      {/* Mostra messaggio informativo al coniuge B --> coniuge dipendente che si trova già i figli iscritti */}
      {childsInAgeSubscribed && childsRegisteredByConsort && (
        <InfoGreyMex text={data?.giftHouse?.alreadySubTxt} />
      )}
    </ComponentContainer>
  );
}
