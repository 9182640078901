import styled from "styled-components";

export const Container = styled.div`
  width: 1216px;
  margin: auto;
  display: block;
  @media screen and (max-width: 1279px) {
    width: calc(100% - 48px);
  }
`;

export const ScrollButtons = styled.div`
  position: fixed;
  right: 26px;
  bottom: 5%;
  transform: translateY(-50%);
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  img {
    cursor: pointer;
  }
  @media screen and (max-width: 1279px) {
    right: 5px;
  }
`;
