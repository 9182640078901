import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ImageContainer } from "../style";
import { setOverallFileSize } from "../../../../pages/FormAnnuncio/FormAnnuncioSlice";
import { useDispatch } from "react-redux";
export function SortableItem({
  id,
  base64URL,
  setCover,
  setDisplayCover,
  file,
  setOtherImages,
  otherImages,
  setOiClassName,
  onCoverChange,
  isFormAble,
}) {
  const dispatch = useDispatch();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: isDragging ? "grabbing" : "grab",
    zIndex: isDragging ? 2 : 1,
  };
  function openImage(e) {
    e.stopPropagation();
    const target = e.target;
    target.parentNode.querySelector(".image-overlay").style.display = "flex";
  }
  function closeImage(e) {
    const target = e.target;
    target.parentNode.style.display = "none";
  }
  return (
    <ImageContainer
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <img src={base64URL} />
      <img
        src="/img/dots.svg"
        className="form-annuncio-dots"
        onClick={openImage}
      />
      <div className="image-overlay">
        <img src="/img/close-community-image.svg" onClick={closeImage} />
        <span
          onClick={(e) => {
            closeImage(e);
            onCoverChange(file, true);
            const exLength = otherImages.length;
            setOtherImages(
              otherImages.reduce((acc, item) => {
                if (item.id !== id) {
                  acc.push(item);
                  item.id = acc.length;
                }
                return acc;
              }, [])
            );
            if (exLength === 1) {
              setOiClassName("dropzone-large");
            }
            isFormAble(true);
          }}
        >
          Imposta copertina
        </span>
        <span
          onClick={(e) => {
            closeImage(e);
            dispatch(setOverallFileSize(-file.size));
            const exLength = otherImages.length;
            setOtherImages(
              otherImages.reduce((acc, item) => {
                if (item.id !== id) {
                  acc.push(item);
                  item.id = acc.length;
                }
                return acc;
              }, [])
            );
            if (exLength === 1) {
              setOiClassName("dropzone-large");
            }
          }}
        >
          Cancella immagine
        </span>
      </div>
    </ImageContainer>
  );
}
