import React from "react";
// style
import { Wrapper, Container, NoContentDiv, FakeRow, X, X2 } from "./style";

function NoContent({ title }) {
  return (
    <Wrapper>
      <Container>
        {/* <NoContentDiv>
          <X />
          <X2 />
          <FakeRow />
          <FakeRow />
          <FakeRow />
          <FakeRow />
          <FakeRow />
          <FakeRow />
        </NoContentDiv> */}
        <p>{title}</p>
      </Container>
    </Wrapper>
  );
}

export default React.memo(NoContent);
