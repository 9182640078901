import { useEffect, useRef } from "react";
import { ComponentContainer } from "../style";
import ErrorField from "./ErrorField/ErrorField";
import ReCAPTCHA from "react-google-recaptcha";
import { setBodyPayload } from "../../../../pages/FormSupporto/FormSupportoSlice";
import { useDispatch } from "react-redux";

export default function Captcha({ data, bodyPayload, allowed }) {
  const dispatch = useDispatch();
  return (
    <>
      <ComponentContainer>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={(value) =>
            dispatch(
              setBodyPayload({
                ...bodyPayload,
                captchaValue: value ? value : "",
              })
            )
          }
        />

        {!bodyPayload?.captchaValue && allowed === false && (
          <ErrorField text={data?.errorMessage} />
        )}
      </ComponentContainer>
    </>
  );
}
