import { createSlice } from "@reduxjs/toolkit";

export const FORM_ISCRIZIONE_GIFTHOUSE_2024 = "FormIscrizioneGiftHouse2024";

export const initialState = {
  components: {},
  checkedConsents: [],
  status: null,
  errorMessage: "",
  guestLimit: 4,
  bodyPayload: {},
  savedBodyPayload: {},
  subscribeError: null,
};

const FormIscrizioneGiftHouse2024Slice = createSlice({
  name: FORM_ISCRIZIONE_GIFTHOUSE_2024,
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setBodyPayload(state, action) {
      state.bodyPayload = action.payload;
    },
    setCheckedConsents(state, action) {
      state.checkedConsents = action.payload;
    },
    initEventInfo(state, action) {
      const Data = action.payload?.data;
      const coniuge = Data?.user?.familyMembers?.find(
        (member) =>
          member.relationship === "CONVIV" || member.relationship === "CONIUG"
      );
      const figli = Data?.user?.familyMembers?.filter(
        (member) => member.relationship === "FIGLIO"
      );
      const childrensInAge = figli?.filter((figlio) => {
        const year = +new Date(figlio?.birthDate).getFullYear();
        if (year > 2013) return figlio;
      });
      const childrenInAgeSubscribed = childrensInAge?.filter(
        (figlio) => figlio?.subscriptionInfo?.isSubscribed === true
      );

      const handleSavedWorkPlace = () => {
        let savedConfiguration;
        let savedWorkPlace = null;
        Data?.event?.appointments?.forEach((appointment) => {
          appointment?.slots?.forEach((slot) => {
            if (slot?.id === Data?.userSubscriptions?.[0]?.appointmentId) {
              savedConfiguration = slot?.configurations?.find(
                (config) => config?.groupName === "deliveryMode"
              );
            }
          });
        });
        switch (savedConfiguration?.fieldValue) {
          case "shipmentMilano":
            savedWorkPlace = "Milano";
            break;
          case "deliveryModena":
            savedWorkPlace = "Modena";
            break;
          case "shipmentOther":
            savedWorkPlace = "Other";
            break;
          case "deliveryMaranello":
            savedWorkPlace = "Maranello";
            break;
          default:
            savedWorkPlace = null;
            break;
        }
        return savedWorkPlace;
      };
      state.savedBodyPayload = {
        type: "giftHouse",
        userFiscalCode: Data?.user?.fiscalCode,
        eventCodeGiftHouse: Data?.event?.code,
        consentsGiftHouse: Data?.event?.consents,
        familyComponents: Data?.user?.familyMembers,
        isMarried: coniuge && coniuge?.isEmployee,
        consortRegistered:
          coniuge?.subscriptionInfo?.isSubscribed && coniuge?.isEmployee,
        childsInAge: childrensInAge,
        childrenInAgeSubscribed: childrenInAgeSubscribed,
        SedeLavoro: handleSavedWorkPlace(),
        appointmentId: Data?.userSubscriptions?.[0]?.appointmentId || null,
      };
      state.bodyPayload = state.savedBodyPayload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setStatusNull(state) {
      state.status = null;
    },
    resetFormDataGiftHouse2024: () => initialState,
  },
});

export const {
  setComponents,
  setError,
  setStatusNull,
  initEventInfo,
  setBodyPayload,
  setCheckedConsents,
  setSubscribeError,
  resetFormDataGiftHouse2024,
} = FormIscrizioneGiftHouse2024Slice.actions;
export default FormIscrizioneGiftHouse2024Slice;
