import { takeLatest, put, call } from "redux-saga/effects";
import {
  axiosCallGetPublic,
  axiosCallPost,
} from "../../utils/axiosCalls/AxiosCalls";

import { setComponents, setError } from "./FormSupportoSlice";
import { FETCH_COMPONENTS, USER_SUPPORT_REQUEST } from "./constants";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../utils/notifications/TriggerNotification";

function* fetchComponentsWorker({ payload }) {
  try {
    const result = yield axiosCallGetPublic(payload.path);
    // const result = yield call(() =>
    //   axios.get("/mocks/mock-form-supporto.json")
    // );
    yield put(setComponents(result.data.data));
  } catch (e) {
    console.error(e);
    yield put(setError(e));
  }
}

function* postBody({ payload }) {
  try {
    document.body.classList.add("loading");
    yield axiosCallPost(
      `${payload.language}/form-login-support`,
      payload.updatedBodyPayload
    );
    // const message =
    //   payload.lang === "it"
    //     ? "Ticket aperto con successo"
    //     : "Ticket opened successfully";
    // showSuccessMessage(message);
    window.location.replace(process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI);
  } catch (e) {
    console.log(e);
    const messageError =
      payload.lang === "it" ? "Operazione fallita" : "Operation failed";
    showErrorMessage(messageError);
  } finally {
    document.body.classList.remove("loading");
  }
}

export default function* FormAnnuncioSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchComponentsWorker);
  yield takeLatest(USER_SUPPORT_REQUEST, postBody);
}
